import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ББЗЗ-ийн мэдээ мэдээлэл хаанаас харах вэ?",
  "description": null,
  "author": "OTC help",
  "category": "news",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`ББЗЗ-ийн мэдээ мэдээлэл хаанаас харах вэ?`}</h2>
    <ol>
      <li parentName="ol">{`Үндсэн цэсний `}<strong parentName="li">{`[News]`}</strong>{` хэсгээс ББЗЗ-ийн платформд нийтлэгдсэн мэдээ мэдээллийн жагсаалтыг харах боломжтой.`}</li>
      <li parentName="ol">{`Мэдээний урд буй '+' тэмдгийг дарж мэдээг дэлгэрүүлэн уншина.`}</li>
      <li parentName="ol">{`Жагсаалтын дээд хэсэг дэх хайлтын хэсгээс мэдээг төрөл болон нэрээр нь шүүж хайх боломжтой.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      